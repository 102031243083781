enum MutationNames {
  FETCH_ONBOARDING_LINK_REQUESTED = 'FETCH_ONBOARDING_LINK_REQUESTED',
  FETCH_ONBOARDING_LINK_FAILED = 'FETCH_ONBOARDING_LINK_FAILED',
  FETCH_ONBOARDING_LINK_SUCCEEDED = 'FETCH_ONBOARDING_LINK_SUCCEEDED',
  FETCH_ONBOARDING_LINK_ERROR = 'FETCH_ONBOARDING_LINK_ERROR',
  FETCH_ONBOARDING_STATUS_REQUESTED = 'FETCH_ONBOARDING_STATUS_REQUESTED',
  FETCH_ONBOARDING_STATUS_FAILED = 'FETCH_ONBOARDING_STATUS_FAILED',
  FETCH_ONBOARDING_STATUS_SUCCEEDED = 'FETCH_ONBOARDING_STATUS_SUCCEEDED',
  RESET_PAYMENTS_PAGE = 'RESET_PAYMENTS_PAGE',
  TAX_RATE_REQUESTED = 'TAX_RATE_REQUESTED',
  TAX_RATE_SUCCEEDED = 'TAX_RATE_SUCCEEDED',
  TAX_RATE_FAILED = 'TAX_RATE_FAILED',
  FETCH_STORE_POLICY_REQUESTED = 'FETCH_STORE_POLICY_REQUESTED',
  FETCH_STORE_POLICY_SUCCEEDED = 'FETCH_STORE_POLICY_SUCCEEDED',
  FETCH_STORE_POLICY_FAILED = 'FETCH_STORE_POLICY_FAILED',
  SAVE_STORE_POLICY_REQUESTED = 'SAVE_STORE_POLICY_REQUESTED',
  SAVE_STORE_POLICY_SUCCEEDED = 'SAVE_STORE_POLICY_SUCCEEDED',
  SAVE_STORE_POLICY_FAILED = 'SAVE_STORE_POLICY_FAILED',
  GET_CUSTOM_FEE_REQUESTED = 'GET_CUSTOM_FEE_REQUESTED',
  GET_CUSTOM_FEE_SUCCEEDED = 'GET_CUSTOM_FEE_SUCCEEDED',
  GET_CUSTOM_FEE_FAILED = 'GET_CUSTOM_FEE_FAILED',
  SAVE_CUSTOM_FEE_REQUESTED = 'SAVE_CUSTOM_FEE_REQUESTED',
  SAVE_CUSTOM_FEE_SUCCEEDED = 'SAVE_CUSTOM_FEE_SUCCEEDED',
  SAVE_CUSTOM_FEE_FAILED = 'SAVE_CUSTOM_FEE_FAILED',
  DELETE_AUTOMATIC_DISCOUNT_SUCCEEDED = 'DELETE_AUTOMATIC_DISCOUNT_SUCCEEDED',
  DELETE_AUTOMATIC_DISCOUNT_REQUESTED = 'DELETE_AUTOMATIC_DISCOUNT_REQUESTED',
  DELETE_AUTOMATIC_DISCOUNT_FAILED = 'DELETE_AUTOMATIC_DISCOUNT_FAILED',
  UPDATE_AUTOMATIC_DISCOUNT_REQUESTED = 'UPDATE_AUTOMATIC_DISCOUNT_REQUESTED',
  UPDATE_AUTOMATIC_DISCOUNT_SUCCEEDED = 'UPDATE_AUTOMATIC_DISCOUNT_SUCCEEDED',
  UPDATE_AUTOMATIC_DISCOUNT_FAILED = 'UPDATE_AUTOMATIC_DISCOUNT_FAILED',
  
}

export default MutationNames;