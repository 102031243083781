































import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator';


@Component({})
export default class SearchBar extends Vue {
  @Prop({ type: String, default: '' }) readonly placeholder!: string;
  @Prop({ type: Boolean, default: false }) readonly shouldClearOnEsc!: boolean;
  @Prop({ type: Boolean, default: true }) readonly preventDefault!: boolean; // To help prevent search bar from auto-submitting forms it may be contained in
  @Prop({ type: Boolean, default: false }) readonly searchOnType!: boolean;
  @Prop({ type: Number, default: 1 }) readonly searchOnTypeMinLength!: number;
  @Ref('inputRef') readonly inputRef!: HTMLInputElement;

  searchText = '';

  @Watch('searchText')
  onSearchTextChange(value: string, oldValue: string) {
    if (!value && oldValue) {
      this.$emit('clearSearch');
    } else if (this.searchOnType && value.length >= this.searchOnTypeMinLength) {
      this.submitSearch();
    }
  }

  @Emit('search')
  submitSearch() {
    return this.searchText;
  }

  onEnterPressed(event: KeyboardEvent) {
    if (this.preventDefault) {
      event.preventDefault();
    }
  }

  onEscPressed() {
    if (this.shouldClearOnEsc) {
      this.clearSearchText();
    }
  }

  clearSearchText() {
    this.searchText = '';
  }

  focus() {
    this.inputRef.focus();
  }
}
