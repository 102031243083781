import { SiteLocationResponse, VerifiedSiteLocation, VerifiedSiteLocationResponse } from '@/Model/siteLocations/types';
import { CustomFeeResponse, Discount, TaxRate } from '@/Model/payments/types';
import { PriceRequest, PriceType, PriceResponse, PromoCode } from '@/Model/payments/types';
import { Day } from '../selectedDays/types';
import { BasePagedDataRequest } from '../shared/types';

export interface GetSessionsRequest extends BasePagedDataRequest {
  programId: number;
  sessionAutoEnrollmentFilter: AutoEnrollmentFilter;
}

export interface GetSessionsResponse {
  results: Session[];
  totalSetCount: number;
}

export interface Session {
  sessionId: number;
  accountId: number;
  programId: number;
  sessionName: string;
  programName: string;
  note1: string;
  note2: string;
  startDateTime: string;
  endDateTime: string;
  selectedDays: Array<Day>;
  enrollmentCount: number;
  scholarCapacity?: number;
  autoEnroll: boolean;
  autoEnrollment: string;
  autoEnrollCapacity?: number;
  waitListCapacity?: number;
  isTimeAdded: boolean;
  ageAsOf: string;
  ageFrom?: number;
  ageTo?: number;
  ageRestriction: string;
  createdAt: string;
  status: number;
  siteLocationId?: number;
  siteLocation?: VerifiedSiteLocation;
  prices?: Array<PriceResponse>;
  defaultPriceId?: string;
  taxRate?: TaxRate | null;
  promoCodes?: Array<PromoCode>;
  customFees: CustomFeeResponse[];
  waitListIsUnlimited?: boolean | null;
  /** Discounts are optionally added */
  discounts?: Array<Discount>;
}

export interface SessionWithExistingSiteLocation extends Omit<Session, 'siteLocation'> {
  siteLocation?: SiteLocationResponse | VerifiedSiteLocationResponse;
}

export interface AddSessionPayload {
  programId?: number;
  sessionName: string;
  note1?: string;
  note2?: string;
  startDate: Date | null;
  endDate: Date | null;
  startTime: string;
  endTime: string;
  selectedDays: Array<Day>;
  siteLocationId?: number;
  scholarCapacity?: number;
  autoEnroll?: boolean;
  ageTo?: number;
  ageFrom?: number;
  waitListCapacity?: number;
  autoEnrollCapacity?: number;
  ageAsOf?: Date | null;
  isTimeAdded: boolean;
  priceRequest: PriceRequest;
  taxRate: TaxRate | null;
  waitListIsUnlimited?: boolean | null;
}

export interface EditSessionPayload extends AddSessionPayload {
  sessionId: number;
  userConfirmsSoftDeleteAttendance: boolean;
}
export interface FilterDropdownOption {
  filterOption: FilterOption;
  displayText: string;
}

export enum FilterOption {
  All = 1,
  AutoEnrollmentOn = 2,
  AutoEnrollmentOff = 3
}

export enum SortOption {
  StartDateAsc = 1,
  StartDateDesc = 2,
  SessionNameAsc = 3,
  SessionNameDesc = 4,
  EnrolledScholarsAsc = 5,
  EnrolledScholarsDesc = 6
}
export interface SortOptionWithDisplayText {
  sortOption: SortOption;
  displayText: string;
}

export interface PaymentFrequencyRadioInputOption {
  id: string;
  option: PriceType;
  displayText: string;
}

export enum AutoEnrollmentFilter {
  All,
  On,
  Off
}

export interface SessionCardPromoCodeListItem {
  id: string;
  displayText: string;
}
