import API_CONST from '@/constants/ApiConst';
import { AddEditProgramPayload, ProgramData, ProgramType } from '@/Model/programs/types';
import { httpClient, httpCMSClient } from '@/services/common/httpClient';

export function getProgramTypes() {
    return httpCMSClient().get<Array<ProgramType>>(`${API_CONST.GET_PRODUCTS}`);
}

export function addProgram(payload: AddEditProgramPayload) {
    return httpClient().post<number>(`${API_CONST.CONFIGURE_PROGRAM}`, payload);
}

export function getProgramData(programId: number) {
    return httpClient().get<ProgramData>(`${API_CONST.GET_PROGRAM_DATA}/${programId}/info`);
};

export function updateProgram(payload: AddEditProgramPayload) {
    return httpClient().put<''>(`${API_CONST.EDIT_PROGRAM_UPDATE}/${payload.programDto.id}`, payload);
}