enum MutationNames {
    FETCH_SESSIONS_REQUESTED = 'FETCH_SESSIONS_REQUESTED',
    FETCH_SESSIONS_SUCCEEDED = 'FETCH_SESSIONS_SUCCEEDED',
    FETCH_SESSIONS_FAILED = 'FETCH_SESSIONS_FAILED',
    RESET_FETCH_SESSIONS = 'RESET_FETCH_SESSIONS',
    SET_SESSIONS = 'SET_SESSIONS',
    CLEAR_SESSIONS = 'CLEAR_SESSIONS',
    SAVE_SESSION_REQUESTED = 'SAVE_SESSION_REQUESTED',
    SAVE_SESSION_SUCCEEDED = 'SAVE_SESSION_SUCCEEDED',
    SAVE_SESSION_FAILED = 'SAVE_SESSION_FAILED',
    RESET_SAVE_SESSION = 'RESET_SAVE_SESSION',
}

export default MutationNames;