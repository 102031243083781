import { Vue } from 'vue-property-decorator';
import { ApiErrorResponse } from '@/Model/errors/api/types';
import { fetchConvenienceFee, fetchStorePolicy, getStripeOnboardingLink, getStripeOnboardingStatus, getTaxRates, saveConvenienceFee, saveStorePolicy } from '@/services/payments/api';
import store from '@/store';
import APP_UTILITIES from '@/utilities/commonFunctions';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import MutationNames from './mutationNames';
import { AddUpdateStorePolicyPayload, ConvenienceFeeGetResponse, ConvenienceFeePutRequest, StorePolicy, StripeAccountResponse, TaxRate } from '@/Model/payments/types';
import { Status } from '@/Model/shared/types';
import { Discount } from '@/Model/payments/types';

const {
  FETCH_ONBOARDING_LINK_REQUESTED,
  FETCH_ONBOARDING_LINK_FAILED,
  FETCH_ONBOARDING_LINK_SUCCEEDED,
  FETCH_ONBOARDING_LINK_ERROR,
  FETCH_ONBOARDING_STATUS_REQUESTED,
  FETCH_ONBOARDING_STATUS_FAILED,
  FETCH_ONBOARDING_STATUS_SUCCEEDED,
  RESET_PAYMENTS_PAGE,
  TAX_RATE_REQUESTED,
  TAX_RATE_SUCCEEDED,
  TAX_RATE_FAILED,
  FETCH_STORE_POLICY_REQUESTED,
  FETCH_STORE_POLICY_SUCCEEDED,
  FETCH_STORE_POLICY_FAILED,
  SAVE_STORE_POLICY_REQUESTED,
  SAVE_STORE_POLICY_SUCCEEDED,
  SAVE_STORE_POLICY_FAILED,
  GET_CUSTOM_FEE_REQUESTED,
  GET_CUSTOM_FEE_SUCCEEDED,
  GET_CUSTOM_FEE_FAILED,
  SAVE_CUSTOM_FEE_FAILED,
  SAVE_CUSTOM_FEE_REQUESTED,
  SAVE_CUSTOM_FEE_SUCCEEDED,
  DELETE_AUTOMATIC_DISCOUNT_REQUESTED,
  DELETE_AUTOMATIC_DISCOUNT_SUCCEEDED,
  DELETE_AUTOMATIC_DISCOUNT_FAILED,
  UPDATE_AUTOMATIC_DISCOUNT_REQUESTED,
  UPDATE_AUTOMATIC_DISCOUNT_SUCCEEDED,
  UPDATE_AUTOMATIC_DISCOUNT_FAILED,

} = MutationNames;


@Module({
  namespaced: true,
  name: 'payments',
  store,
  dynamic: true
})

export class PaymentsModule extends VuexModule {
  didOnboardingLinkFetchFail: boolean = false;
  isFetchingOnboardingLink: boolean = false;
  onboardingLinkFetchError: ApiErrorResponse | null = null;

  didOnboardingStatusFetchFail: boolean = false;
  isFetchingOnboardingStatus: boolean = false;
  onboardingStatusFetchError: ApiErrorResponse | null = null;

  stripeAccountResponse: StripeAccountResponse | null = null;

  isFetchingTaxRates = false;
  didFailFetchingTaxRates = false;
  taxRateFailError: ApiErrorResponse | null = null;
  taxRates: TaxRate[] = [];

  isFetchingStorePolicy = false;
  didFetchStorePolicyFail = false;
  fetchStorePolicyFailedError: ApiErrorResponse | null = null;
  storePolicy: StorePolicy = {
    enabled: false,
    url: ''
  };

  isSavingStorePolicy = false;
  didSaveStorePolicyFail = false;
  saveStorePolicyFailedError: ApiErrorResponse | null = null;

  isLoadingCustomFee = false;
  isSavingCustomFee = false;
  didSavingCustomFeeFail = false;
  customFeeError: ApiErrorResponse | null = null;
  customFee: ConvenienceFeeGetResponse | null = null;

  //Discount list object
  discountList: Discount[] = [];
  isConfigurationDiscountMenuOpen: boolean = false;

  isLoadingDiscount = false;

  isDeletingDiscount = false;
  didDeleteDiscountFail = false;

  isUpdatingDiscount = false;
  didUpdateDiscountFail = false;

  discountError: ApiErrorResponse | null = null;


  get discountListReference () {
    return this.discountList;
  }

  @Mutation
  [GET_CUSTOM_FEE_REQUESTED]() {
    this.isLoadingCustomFee = true;
    this.customFee = null;
  }

  @Mutation
  [GET_CUSTOM_FEE_SUCCEEDED](fee: ConvenienceFeeGetResponse | null) {
    this.isLoadingCustomFee = false;
    this.customFee = fee;
    this.customFeeError = null;
  }

  @Mutation
  [GET_CUSTOM_FEE_FAILED](error: ApiErrorResponse) {
    this.isLoadingCustomFee = false;
    this.customFeeError = error;
  }

  @Mutation
  [SAVE_CUSTOM_FEE_REQUESTED]() {
    this.isSavingCustomFee = true;
    this.didSavingCustomFeeFail = false;
  }

  @Mutation
  [SAVE_CUSTOM_FEE_SUCCEEDED](fee: ConvenienceFeeGetResponse) {
    this.isSavingCustomFee = false;
    this.customFee = fee;
    this.customFeeError = null;
    this.didSavingCustomFeeFail = false;
  }

  @Mutation
  [SAVE_CUSTOM_FEE_FAILED](error: ApiErrorResponse) {
    this.isSavingCustomFee = false;
    this.customFeeError = error;
    this.didSavingCustomFeeFail = true;
  }
  
  /**
   * Delete discount start
   */
  // TODO: verify if logic here is needed or if it should be removed from the global store and handled in the component
  @Mutation
  [DELETE_AUTOMATIC_DISCOUNT_REQUESTED]() {
    // TODO: Add logic related to states to delete discount
    this.isDeletingDiscount = true;
    this.didDeleteDiscountFail = false;
  }

  @Mutation
  [DELETE_AUTOMATIC_DISCOUNT_SUCCEEDED](discounts: any) {
    this.discountList = discounts;
  }

  @Mutation
  [DELETE_AUTOMATIC_DISCOUNT_FAILED](error: ApiErrorResponse) {
    // TODO: Add logic related to states to delete discount
    this.isDeletingDiscount = false;
    this.discountError = error;
    this.didDeleteDiscountFail = true;
  }
  /**
   * Delete discount end
   */

 
  /**
   * Update discount start
   */
  // TODO: verify if logic here is needed or if it should be removed from the global store and handled in the component
  @Mutation
  [UPDATE_AUTOMATIC_DISCOUNT_REQUESTED]() {
    // TODO: Add logic related to states to delete discount
  }

  @Mutation
  [UPDATE_AUTOMATIC_DISCOUNT_SUCCEEDED](discounts: any) {
    this.discountList = discounts;
  }

  @Mutation
  [UPDATE_AUTOMATIC_DISCOUNT_FAILED](error: ApiErrorResponse) {
    // TODO: Add logic related to states to delete discount
  }

  /**
   * Update discount end
   */

  @Mutation
  [FETCH_ONBOARDING_LINK_REQUESTED]() {
    this.didOnboardingLinkFetchFail = false;
    this.isFetchingOnboardingLink = true;
    this.onboardingLinkFetchError = null;
  }

  @Mutation
  [FETCH_ONBOARDING_LINK_FAILED]() {
    this.didOnboardingLinkFetchFail = true;
    this.isFetchingOnboardingLink = false;
    this.onboardingLinkFetchError = null;
  }

  @Mutation
  [FETCH_ONBOARDING_LINK_SUCCEEDED]() {
    this.didOnboardingLinkFetchFail = false;
    this.isFetchingOnboardingLink = false;
    this.onboardingLinkFetchError = null;
  }

  @Mutation
  [FETCH_ONBOARDING_LINK_ERROR](error: any) {
    this.didOnboardingLinkFetchFail = true;
    this.isFetchingOnboardingLink = false;
    this.onboardingLinkFetchError = APP_UTILITIES.errorToApiErrorResponse(error);
  }

  @Mutation
  [FETCH_ONBOARDING_STATUS_REQUESTED]() {
    this.stripeAccountResponse = null;
    this.didOnboardingStatusFetchFail = false;
    this.isFetchingOnboardingStatus = true;
    this.onboardingStatusFetchError = null;
  }

  @Mutation
  [FETCH_ONBOARDING_STATUS_FAILED](error: any) {
    this.stripeAccountResponse = null;
    this.didOnboardingStatusFetchFail = true;
    this.isFetchingOnboardingStatus = false;
    this.onboardingStatusFetchError = APP_UTILITIES.errorToApiErrorResponse(error);
  }

  @Mutation
  [FETCH_ONBOARDING_STATUS_SUCCEEDED](response: StripeAccountResponse) {
    this.stripeAccountResponse = response;
    this.didOnboardingStatusFetchFail = false;
    this.isFetchingOnboardingStatus = false;
    this.onboardingStatusFetchError = null;
  }

  @Mutation
  [RESET_PAYMENTS_PAGE]() {
    this.didOnboardingLinkFetchFail = false;
    this.isFetchingOnboardingLink = false;
    this.onboardingLinkFetchError = null;

    this.didOnboardingStatusFetchFail = false;
    this.isFetchingOnboardingStatus = false;
    this.onboardingStatusFetchError = null;

    this.stripeAccountResponse = null;

    this.isLoadingCustomFee = false;
    this.isSavingCustomFee = false;
    this.customFee = null;
    this.customFeeError = null;

  }

  @Mutation
  [TAX_RATE_REQUESTED]() {
    this.isFetchingTaxRates = true;
    this.didFailFetchingTaxRates = false;
    this.taxRateFailError = null;
  }

  @Mutation
  [TAX_RATE_SUCCEEDED](taxRates: TaxRate[]) {
    this.taxRates = taxRates;
    this.isFetchingTaxRates = false;
    this.didFailFetchingTaxRates = false;
    this.taxRateFailError = null;
  }

  @Mutation
  [TAX_RATE_FAILED](error: ApiErrorResponse) {
    this.taxRates = [];
    this.isFetchingTaxRates = false;
    this.didFailFetchingTaxRates = true;
    this.taxRateFailError = error;
  }

  @Mutation
  [FETCH_STORE_POLICY_REQUESTED]() {
    this.isFetchingStorePolicy = true;
    this.didFetchStorePolicyFail = false;
    this.fetchStorePolicyFailedError = null;
  }

  @Mutation
  [FETCH_STORE_POLICY_SUCCEEDED](storePolicy: StorePolicy) {
    this.isFetchingStorePolicy = false;
    this.didFetchStorePolicyFail = false;
    this.fetchStorePolicyFailedError = null;
    this.storePolicy = storePolicy;
  }

  @Mutation
  [FETCH_STORE_POLICY_FAILED](error: ApiErrorResponse) {
    this.isFetchingStorePolicy = false;
    this.didFetchStorePolicyFail = true;
    this.fetchStorePolicyFailedError = error;
  }

  @Mutation
  [SAVE_STORE_POLICY_REQUESTED]() {
    this.isSavingStorePolicy = true;
    this.didSaveStorePolicyFail = false;
    this.saveStorePolicyFailedError = null;
  }

  @Mutation
  [SAVE_STORE_POLICY_SUCCEEDED](storePolicy: StorePolicy) {
    this.isSavingStorePolicy = false;
    this.didSaveStorePolicyFail = false;
    this.saveStorePolicyFailedError = null;
    this.storePolicy = storePolicy;
  }

  @Mutation
  [SAVE_STORE_POLICY_FAILED](error: ApiErrorResponse) {
    this.isSavingStorePolicy = false;
    this.didSaveStorePolicyFail = true;
    this.saveStorePolicyFailedError = error;
  }

  @Action
  changeCustomFeeStatus() {
    if(this.customFee) {
      this.customFee.status == Status.Active
        ? this.customFee.status = Status.Inactive
        : this.customFee.status = Status.Active;
    }
  }

  @Action
  async getOnboardingLink(accountId: number): Promise<string | undefined> {
    try {
      this.FETCH_ONBOARDING_LINK_REQUESTED();
      const response = await getStripeOnboardingLink(accountId);
      if (response && response.status == 200) {
        this.FETCH_ONBOARDING_LINK_SUCCEEDED();
        return response.data;
      }
      else {
        this.FETCH_ONBOARDING_LINK_FAILED();
        return;
      }
    }
    catch (error) {
      this.FETCH_ONBOARDING_LINK_ERROR(error);
      return;
    }
  }

  @Action
  async getOnboardingStatus(accountId: number) {
    try {
      this.FETCH_ONBOARDING_STATUS_REQUESTED();

      const response = await getStripeOnboardingStatus(accountId);

      this.FETCH_ONBOARDING_STATUS_SUCCEEDED(response.data);

    }
    catch (error) {
      this.FETCH_ONBOARDING_STATUS_FAILED(error);
    }
  }

  @Action
  resetOnboardingState() {
    this.RESET_PAYMENTS_PAGE();
  }

  @Action
  async getTaxRates(accountId: number) {
    try {
      this.TAX_RATE_REQUESTED();

      const response = (await getTaxRates(accountId)).data;
      this.TAX_RATE_SUCCEEDED(response.taxRates);
    }
    catch (error) {
      const apiErrorResponse: ApiErrorResponse = APP_UTILITIES.errorToApiErrorResponse(error);
      this.TAX_RATE_FAILED(apiErrorResponse);
    }
  }

  @Action
  async fetchStorePolicy(accountId: number) {
    try {
      this.FETCH_STORE_POLICY_REQUESTED();
      const response = await fetchStorePolicy(accountId);
      this.FETCH_STORE_POLICY_SUCCEEDED(response.data);
    }
    catch (error) {
      const apiErrorResponse: ApiErrorResponse = APP_UTILITIES.errorToApiErrorResponse(error);
      this.FETCH_STORE_POLICY_FAILED(apiErrorResponse);
    }
  }

  @Action
  async saveStorePolicy(payload: AddUpdateStorePolicyPayload) {
    try {
      this.SAVE_STORE_POLICY_REQUESTED();
      await saveStorePolicy(payload);
      this.SAVE_STORE_POLICY_SUCCEEDED(payload);
    }
    catch (error) {
      const apiErrorResponse: ApiErrorResponse = APP_UTILITIES.errorToApiErrorResponse(error);
      this.SAVE_STORE_POLICY_FAILED(apiErrorResponse);
    }
  }


  @Action
  async retrieveCustomFee() {
    try {
      this.GET_CUSTOM_FEE_REQUESTED();

      const accountId = Number(APP_UTILITIES.getCookie('accountId')) || 0;

      const response = await fetchConvenienceFee(accountId);

      this.GET_CUSTOM_FEE_SUCCEEDED(response.data);
    }
    catch (error) {
      const apiErrorResponse: ApiErrorResponse = APP_UTILITIES.errorToApiErrorResponse(error);
      this.GET_CUSTOM_FEE_FAILED(apiErrorResponse);
    }
  }

  @Action
  async updateCustomFee(fee: ConvenienceFeeGetResponse) {
    try {

      const accountId = Number(APP_UTILITIES.getCookie('accountId')) || 0;

      const updatedFee = {
        ...fee,
        accountId: accountId
      } as ConvenienceFeePutRequest;

      if(updatedFee.id == 0) {
        updatedFee.id = undefined;
      }

      this.SAVE_CUSTOM_FEE_REQUESTED();

      const response = await saveConvenienceFee(updatedFee);

      this.SAVE_CUSTOM_FEE_SUCCEEDED(response.data);
    }
    catch (error) {
      const apiErrorResponse: ApiErrorResponse = APP_UTILITIES.errorToApiErrorResponse(error);
      this.SAVE_CUSTOM_FEE_FAILED(apiErrorResponse);
    }
  }

  @Action
  async deleteAutomaticDiscount(discountId: number) {
    try {
     
      const testDelte = this.discountList.filter(discount => discount.id !==  discountId);

      // TODO: add logic for request once integration is done and loading data
      // this.SAVE_REQUESTED();
      const timeoutPromise = new Promise((resolve) => {
        setTimeout(() => {
          resolve(testDelte);
        }, 2000);
      });

      const result = await timeoutPromise;


      this.DELETE_AUTOMATIC_DISCOUNT_SUCCEEDED(result);
    }
    catch (error) {
      // const apiErrorResponse: ApiErrorResponse = APP_UTILITIES.errorToApiErrorResponse(
      //   error
      // );
      // TODO: add setup for error based on error on integration 
      // this.SAVE_FAILED(apiErrorResponse);
    }
  }


  @Mutation
  setIsConfiguratioDiscountMenuOpen(isOpen: boolean) {
    this.isConfigurationDiscountMenuOpen = isOpen;
  }
}

export default getModule(PaymentsModule);