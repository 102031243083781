import { ApiErrorResponse } from '@/Model/errors/api/types';
import { AddEditProgramPayload, ProgramData, ProgramType } from '@/Model/programs/types';
import { getProgramTypes, addProgram, getProgramData, updateProgram } from '@/services/programs/api';
import store from '@/store';
import APP_UTILITIES from '@/utilities/commonFunctions';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { FETCH_PROGRAM_TYPES_REQUESTED, FETCH_PROGRAM_TYPES_SUCCEEDED, FETCH_PROGRAM_TYPES_FAILED, RESET_FETCH_PROGRAM_TYPES, SET_PROGRAM_TYPES, SAVE_PROGRAM_REQUESTED, SAVE_PROGRAM_SUCCEEDED, SAVE_PROGRAM_FAILED, RESET_SAVE_PROGRAM, FETCH_PROGRAM_DATA_REQUESTED, FETCH_PROGRAM_DATA_SUCCEEDED, FETCH_PROGRAM_DATA_FAILED, RESET_FETCH_PROGRAM_DATA, SET_PROGRAM_DATA } from './mutationNames';

@Module({
    namespaced: true,
    name: 'programs',
    store,
    dynamic: true
})
export class ProgramsModule extends VuexModule {
    programTypes: Array<ProgramType> = [];
    isFetchingProgramTypes = false;
    didFetchProgramTypesFail = false;
    fetchProgramTypesFailedError: ApiErrorResponse | null = null;

    isSavingProgram = false;
    didSaveProgramFail = false;
    saveProgramFailedError: ApiErrorResponse | null = null;

    programData: ProgramData | null = null;
    isInitialFetchingProgramData = true;
    isFetchingProgramData = false;
    didFetchProgramDataFail = false;
    fetchProgramDataFailedError: ApiErrorResponse | null = null;

    @Mutation
    [FETCH_PROGRAM_TYPES_REQUESTED]() {
        this.isFetchingProgramTypes = true;
        this.didFetchProgramTypesFail = false;
        this.fetchProgramTypesFailedError = null;
    }

    @Mutation
    [FETCH_PROGRAM_TYPES_SUCCEEDED]() {
        this.isFetchingProgramTypes = false;
        this.didFetchProgramTypesFail = false;
        this.fetchProgramTypesFailedError = null;
    }

    @Mutation
    [FETCH_PROGRAM_TYPES_FAILED](error: ApiErrorResponse) {
        this.isFetchingProgramTypes = false;
        this.didFetchProgramTypesFail = true;
        this.fetchProgramTypesFailedError = error;
    }

    @Mutation
    [RESET_FETCH_PROGRAM_TYPES]() {
        this.isFetchingProgramTypes = false;
        this.didFetchProgramTypesFail = false;
        this.fetchProgramTypesFailedError = null;
    }

    @Mutation
    [SET_PROGRAM_TYPES](programTypes: Array<ProgramType>) {
        this.programTypes = programTypes;
    }

    @Mutation
    [SAVE_PROGRAM_REQUESTED]() {
        this.isSavingProgram = true;
        this.didSaveProgramFail = false;
        this.saveProgramFailedError = null;
    }

    @Mutation
    [SAVE_PROGRAM_SUCCEEDED]() {
        this.isSavingProgram = false;
        this.didSaveProgramFail = false;
        this.saveProgramFailedError = null;
    }

    @Mutation
    [SAVE_PROGRAM_FAILED](error: ApiErrorResponse) {
        this.isSavingProgram = false;
        this.didSaveProgramFail = true;
        this.saveProgramFailedError = error;
    }

    @Mutation
    [RESET_SAVE_PROGRAM]() {
        this.isSavingProgram = false;
        this.didSaveProgramFail = false;
        this.saveProgramFailedError  = null;
    }

    @Mutation
    [FETCH_PROGRAM_DATA_REQUESTED]() {
        this.isFetchingProgramData = true;
        this.didFetchProgramDataFail = false;
        this.fetchProgramDataFailedError  = null;
    }

    @Mutation
    [FETCH_PROGRAM_DATA_SUCCEEDED]() {
        this.isFetchingProgramData = false;
        this.didFetchProgramDataFail = false;
        this.fetchProgramDataFailedError  = null;
        this.isInitialFetchingProgramData = false;
    }

    @Mutation
    [FETCH_PROGRAM_DATA_FAILED](error: ApiErrorResponse) {
        this.isFetchingProgramData = false;
        this.didFetchProgramDataFail = true;
        this.fetchProgramDataFailedError = error;
        this.isInitialFetchingProgramData = false;
    }

    @Mutation
    [RESET_FETCH_PROGRAM_DATA]() {
        this.isInitialFetchingProgramData = true;
        this.isFetchingProgramData = false;
        this.didFetchProgramDataFail = false;
        this.fetchProgramDataFailedError  = null;
    }

    @Mutation
    [SET_PROGRAM_DATA](programData: ProgramData | null) {
        this.programData = programData;
    }

    @Action
    async fetchProgramTypes() {
        try {
            this.FETCH_PROGRAM_TYPES_REQUESTED();
            const response = await getProgramTypes();
            this.SET_PROGRAM_TYPES(response.data);
            this.FETCH_PROGRAM_TYPES_SUCCEEDED();
            return response;
        } catch (error) {
            const apiErrorResponse: ApiErrorResponse = APP_UTILITIES.errorToApiErrorResponse(error);
            this.FETCH_PROGRAM_TYPES_FAILED(apiErrorResponse);
        }
    }

    @Action
    async createProgram(payload: AddEditProgramPayload) {
        try {
            this.SAVE_PROGRAM_REQUESTED();
            const response = await addProgram(payload);
            const newProgramId: number = response.data; // The API literally just returns the ID and that's it
            APP_UTILITIES.setCookie('program_id', `${newProgramId}`, 1); // TODO (REFACTOR): This is how the app currently stores/passes the ID to the next page to fetch data -- not ideal, but keeping for now
            this.SAVE_PROGRAM_SUCCEEDED();
            return response;
        } catch (error) {
            const apiErrorResponse: ApiErrorResponse = APP_UTILITIES.errorToApiErrorResponse(error);
            this.SAVE_PROGRAM_FAILED(apiErrorResponse);
        }
    }

    @Action
    async fetchProgramData(programId: number) {
        try {
            this.FETCH_PROGRAM_DATA_REQUESTED();
            const response = await getProgramData(programId);
            this.SET_PROGRAM_DATA(response && response.data);
            this.FETCH_PROGRAM_DATA_SUCCEEDED();
            return response;
        } catch (error) {
            const apiErrorResponse: ApiErrorResponse = APP_UTILITIES.errorToApiErrorResponse(error);
            this.FETCH_PROGRAM_DATA_FAILED(apiErrorResponse);
        }
    }

    @Action
    async updateProgram(payload: AddEditProgramPayload) {
        try {
            this.SAVE_PROGRAM_REQUESTED();
            const response = await updateProgram(payload);
            this.SAVE_PROGRAM_SUCCEEDED();
            return response;
        } catch (error) {
            const apiErrorResponse: ApiErrorResponse = APP_UTILITIES.errorToApiErrorResponse(error);
            this.SAVE_PROGRAM_FAILED(apiErrorResponse);
        }
    }
}
export default getModule(ProgramsModule);