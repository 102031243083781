

























import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { Day, DayAbbreviation } from '@/Model/selectedDays/types';
import { ScreenText } from '@/lang/ScreenText';
import APP_UTILITIES from '@/utilities/commonFunctions';


@Component({})
export default class SelectedDays extends Vue {
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;
  @PropSync('selectedDays', { type: Array }) selectedDaysSync!: Array<Day>;

  readonly screenText = new ScreenText();

  get daysAbbreviations(): Array<DayAbbreviation> {
    return APP_UTILITIES.getDaysAbbreviations();
  }
}
