









import { Component, Vue } from 'vue-property-decorator';
import UIkit from 'uikit';

@Component({})
export default class ContextMenuItem extends Vue {

  emitClick() {
    this.$emit('click');
    UIkit.dropdown(this.$el.parentElement).hide();
  }
}

