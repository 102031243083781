import API_CONST from '@/constants/ApiConst';
import { AddSessionPayload, AutoEnrollmentFilter, EditSessionPayload, GetSessionsRequest, GetSessionsResponse, Session } from '@/Model/sessions/types';
import { SortDirection } from '@/Model/shared/types';
import { httpClient } from '@/services/common/httpClient';
import { AxiosResponse } from 'axios';

export function addNewSession(newSession: AddSessionPayload) {
  return httpClient().post(`${API_CONST.SESSIONS_END_POINT}`, { ...newSession });
}

export function updateSession(payload: EditSessionPayload) {
  return httpClient().put(`${API_CONST.SESSIONS_END_POINT}`, payload);
}

export function getSessionsForProgram(programId: number): Promise<AxiosResponse<Array<Session>>> {
  return httpClient().get<Array<Session>>(`${API_CONST.PROGRAM_SESSIONS_ENDPOINT}/${programId}`);
}

export async function getPagedSessionsForProgram(request: GetSessionsRequest): Promise<AxiosResponse<GetSessionsResponse>> {

  const url = `${API_CONST.SESSIONS_END_POINT}` +
    `?programId=${encodeURIComponent(request.programId)}` +
    `&page=${encodeURIComponent(request.page || '')}` +
    `&sortField=${encodeURIComponent(request.sortField || '')}` +
    `&sortDir=${encodeURIComponent(request.sortDir || SortDirection.Undefined)}` +
    `&searchField=${encodeURIComponent(request.searchField || '')}` +
    `&search=${encodeURIComponent(request.search || '')}` +
    `&pageItemCount=${encodeURIComponent(request.pageItemCount || '')}` +
    `&sessionAutoEnrollmentFilter=${encodeURIComponent(request.sessionAutoEnrollmentFilter || AutoEnrollmentFilter.All)}`;

  return httpClient().get<GetSessionsResponse>(url);
}
