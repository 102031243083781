// Program Types
export const FETCH_PROGRAM_TYPES_REQUESTED = 'FETCH_PROGRAM_TYPES_REQUESTED';
export const FETCH_PROGRAM_TYPES_SUCCEEDED = 'FETCH_PROGRAM_TYPES_SUCCEEDED';
export const FETCH_PROGRAM_TYPES_FAILED = 'FETCH_PROGRAM_TYPES_FAILED';
export const RESET_FETCH_PROGRAM_TYPES = 'RESET_FETCH_PROGRAM_TYPES';
export const SET_PROGRAM_TYPES = 'SET_PROGRAM_TYPES';

// Save Program
export const SAVE_PROGRAM_REQUESTED = 'SAVE_PROGRAM_REQUESTED';
export const SAVE_PROGRAM_SUCCEEDED = 'SAVE_PROGRAM_SUCCEEDED';
export const SAVE_PROGRAM_FAILED = 'SAVE_PROGRAM_FAILED';
export const RESET_SAVE_PROGRAM = 'RESET_SAVE_PROGRAM';

// Program Data
export const FETCH_PROGRAM_DATA_REQUESTED = 'FETCH_PROGRAM_DATA_REQUESTED';
export const FETCH_PROGRAM_DATA_SUCCEEDED = 'FETCH_PROGRAM_DATA_SUCCEEDED';
export const FETCH_PROGRAM_DATA_FAILED = 'FETCH_PROGRAM_DATA_FAILED';
export const RESET_FETCH_PROGRAM_DATA = 'RESET_FETCH_PROGRAM_DATA'; 
export const SET_PROGRAM_DATA = 'SET_PROGRAM_DATA';